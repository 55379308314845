<template>
  <div class="-trend">
    <div class="c_main -pad-btm">
      <header class="c_main_hd">
        <div class="inner">
          <nav class="c_pnkz">
            <ul class="c_pnkz_list">
              <li>
                <a class="c_pointer" @click="$router.push({ name: 'Home'})">ホーム</a>
              </li>
              <li>サイバー最新トレンド</li>
            </ul>
          </nav>
          <h1 class="c_main_ttl">サイバー最新トレンド</h1>
        </div>
      </header>
      <div class="c_main_body">
        <div class="inner">
          <!--
          <section class="c_rcmnd">
            <h2 class="c_rcmnd_ttl">おすすめツール</h2>
            <div class="c_rcmnd_wrap">
              <div class="c_rcmnd_cnt">
                <swiper>
                  <swiper-slide>
                    <a class="c_rcmnd_item" href="aaa">
                      <div class="c_rcmnd_item_row">
                        <figure class="c_rcmnd_item_img">
                          <img src="https://placehold.jp/65x65.png" alt />
                        </figure>
                        <div class="c_rcmnd_item_cnt">
                          <time>2021.00.00</time>
                          <em>スマホ・SNS外国語吐き出し結果日本語分析君</em>
                        </div>
                      </div>
                      <p class="c_rcmnd_item_info">
                        <span>ver 2.50</span> （
                        <time>2021.00.00</time>）
                      </p>
                      <p
                        class="c_rcmnd_item_disc"
                      >日本語文を形態素に分割し、品詞、読みがなの付与、統計情報を表示します。機会学習で使用するデータにも形態素解析がよく利用されます。</p>
                    </a>
                  </swiper-slide>
                  <swiper-slide>
                    <a class="c_rcmnd_item" href="aaa">
                      <div class="c_rcmnd_item_row">
                        <figure class="c_rcmnd_item_img">
                          <img src="https://placehold.jp/65x65.png" alt />
                        </figure>
                        <div class="c_rcmnd_item_cnt">
                          <time>2021.00.00</time>
                          <em>スマホ・SNS外国語吐き出し結果日本語分析君</em>
                        </div>
                      </div>
                      <p class="c_rcmnd_item_info">
                        <span>ver 2.50</span> （
                        <time>2021.00.00</time>）
                      </p>
                      <p
                        class="c_rcmnd_item_disc"
                      >日本語文を形態素に分割し、品詞、読みがなの付与、統計情報を表示します。機会学習で使用するデータにも形態素解析がよく利用されます。</p>
                    </a>
                  </swiper-slide>
                  <swiper-slide>
                    <a class="c_rcmnd_item" href="aaa">
                      <div class="c_rcmnd_item_row">
                        <figure class="c_rcmnd_item_img">
                          <img src="https://placehold.jp/65x65.png" alt />
                        </figure>
                        <div class="c_rcmnd_item_cnt">
                          <time>2021.00.00</time>
                          <em>スマホ・SNS外国語吐き出し結果日本語分析君</em>
                        </div>
                      </div>
                      <p class="c_rcmnd_item_info">
                        <span>ver 2.50</span> （
                        <time>2021.00.00</time>）
                      </p>
                      <p
                        class="c_rcmnd_item_disc"
                      >日本語文を形態素に分割し、品詞、読みがなの付与、統計情報を表示します。機会学習で使用するデータにも形態素解析がよく利用されます。</p>
                    </a>
                  </swiper-slide>
                  <swiper-slide>
                    <a class="c_rcmnd_item" href="aaa">
                      <div class="c_rcmnd_item_row">
                        <figure class="c_rcmnd_item_img">
                          <img src="https://placehold.jp/65x65.png" alt />
                        </figure>
                        <div class="c_rcmnd_item_cnt">
                          <time>2021.00.00</time>
                          <em>スマホ・SNS外国語吐き出し結果日本語分析君</em>
                        </div>
                      </div>
                      <p class="c_rcmnd_item_info">
                        <span>ver 2.50</span> （
                        <time>2021.00.00</time>）
                      </p>
                      <p
                        class="c_rcmnd_item_disc"
                      >日本語文を形態素に分割し、品詞、読みがなの付与、統計情報を表示します。機会学習で使用するデータにも形態素解析がよく利用されます。</p>
                    </a>
                  </swiper-slide>
                </swiper>
                <button class="c_rcmnd_slide_arrow -l c_rcmnd_slide_arrow_prev" type="button">
                  <span class="icon-arrow-l"></span>
                </button>
                <button class="c_rcmnd_slide_arrow -r c_rcmnd_slide_arrow_next" type="button">
                  <span class="icon-arrow-r"></span>
                </button>
              </div>
              <div class="c_rcmnd_pager"></div>
            </div>
          </section>
          -->
          <section-contents-list :categoryId="categoryId" videoRouterName="TrendSingle"></section-contents-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionContentsList from "@/components/SectionContentsList";

export default {
  components: {
    SectionContentsList
  },
  computed: {
    categoryId() {
      return this.$store.getters.getCategoryIdByName("サイバー最新トレンド");
    }
  },
};
</script>
